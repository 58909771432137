import _export from "../internals/export";
import _arrayFill from "../internals/array-fill";
import _addToUnscopables from "../internals/add-to-unscopables";
var $ = _export;
var fill = _arrayFill;
var addToUnscopables = _addToUnscopables; // `Array.prototype.fill` method
// https://tc39.es/ecma262/#sec-array.prototype.fill

$({
  target: "Array",
  proto: true
}, {
  fill: fill
}); // https://tc39.es/ecma262/#sec-array.prototype-@@unscopables

addToUnscopables("fill");
export default {};